<template>
  <div class="security-check">
    <el-tabs v-model="activeName" ref="tabs" @tab-click="handleClick">
      <el-tab-pane label="吊篮检查记录" name="first">
        <inspection-record v-if="activeName == 'first'"></inspection-record>
      </el-tab-pane>
      <el-tab-pane label="吊篮巡检项字典" name="second">
        <industrial-park v-if="activeName == 'second'"></industrial-park>
      </el-tab-pane>
      <el-tab-pane label="吊篮使用记录" name="zero">
        <use-record v-if="activeName == 'zero'"></use-record>
      </el-tab-pane>
      <el-tab-pane label="登高车使用记录" name="mechanical">
        <mechanical-record v-if="activeName == 'mechanical'"></mechanical-record>
      </el-tab-pane>
      <el-tab-pane label="吊车使用记录" name="mechanical2">
        <mechanical-record2 v-if="activeName == 'mechanical2'"></mechanical-record2>
      </el-tab-pane>
      <el-tab-pane v-if="isProject" label="吊篮日检报表" name="third">
        <inspection-ledger v-if="activeName == 'third'"></inspection-ledger>
      </el-tab-pane>
      <el-tab-pane v-if="isProject" label="吊篮在场台班" name="lastOne">
        <dianlan-zaichang v-if="activeName == 'lastOne'"></dianlan-zaichang>
      </el-tab-pane>
      <el-tab-pane v-if="isProject" label="吊篮月巡检台账" name="fourth">
        <mechanical-desk v-if="activeName == 'fourth'"></mechanical-desk>
      </el-tab-pane>
      <el-tab-pane v-if="isCompany" label="吊篮巡检汇总台账" name="fifth">
        <one-com v-if="activeName == 'fifth'"></one-com>
      </el-tab-pane>
      <el-tab-pane v-if="isCompany" label="吊篮台班汇总台账" name="sixth">
        <two-com v-if="activeName == 'sixth'"></two-com>
      </el-tab-pane>
      <el-tab-pane v-if="isProject" label="吊篮使用统计" name="seventh">
        <three-com v-if="activeName == 'seventh'"></three-com>
      </el-tab-pane>
      <el-tab-pane v-if="isProject" label="吊篮使用率统计" name="eighth">
        <four-com v-if="activeName == 'eighth'"></four-com>
      </el-tab-pane>
      <el-tab-pane v-if="isProject" label="登高车使用统计" name="tenth">
        <five-com v-if="activeName == 'tenth'"></five-com>
      </el-tab-pane>
      <el-tab-pane v-if="isProject" label="吊车使用统计" name="tenth2">
        <five-com2 v-if="activeName == 'tenth2'"></five-com2>
      </el-tab-pane>
      <el-tab-pane v-if="isCompany" label="登高车台班汇总台账" name="eleventh">
        <six-com v-if="activeName == 'eleventh'"></six-com>
      </el-tab-pane>
      <el-tab-pane v-if="isCompany" label="吊车台班汇总台账" name="eleventh2">
        <six-com2 v-if="activeName == 'eleventh2'"></six-com2>
      </el-tab-pane>
      <el-tab-pane label="吊篮综合台班查询" name="fifth2">
        <one-com2 v-if="activeName == 'fifth2'"></one-com2>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { tabIndex } from '@/utils/local-cache'
  export default {
    name: 'security-check',
    components: {
      InspectionRecord:() => import('./checkComponents/inspection-record'),
      IndustrialPark:() => import('./checkComponents/industrial-park.vue'),
      InspectionLedger:() => import('./checkComponents/inspection-ledger.vue'),
      MechanicalDesk:() => import('./checkComponents/mechanical-desk.vue'),
      MechanicalRecord:() => import('./checkComponents/mechanical-record'),
      MechanicalRecord2:() => import('./checkComponents/mechanical-record2'),
      OneCom:() => import('./checkComponents/one'),
      OneCom2:() => import('./checkComponents/one2'),
      TwoCom:() => import('./checkComponents/two'),
      ThreeCom:() => import('./checkComponents/three'),
      FourCom:() => import('./checkComponents/four'),
      FiveCom:() => import('./checkComponents/five'),
      FiveCom2:() => import('./checkComponents/five2'),
      SixCom:() => import('./checkComponents/six'),
      SixCom2:() => import('./checkComponents/six2'),
      UseRecord:() => import('./checkComponents/use-record.vue'),
      DianlanZaichang:() => import('./checkComponents/dianlan-zaichang.vue')
    },
    props: {
    },

    data() {
      return {
        activeName: 'first'
      }
    },

    computed: {
      ...mapGetters(['companyId', 'optionItem']),
      departmentType(){
        return this.optionItem.departmentType
      },
      isProject(){
        return this.optionItem && this.optionItem.departmentType == 'project';
      },
      // 是否为集团人员
      isCompany(){
        return this.optionItem && this.optionItem.departmentType == 'company';
      }
    },

    watch: {
      optionItem: {
        immediate: true,
        handler(nv) {
          if (!nv) return
          if (this.oldType && nv && nv.departmentType !== this.oldType) {
            if (this.activeName != 'first' && this.activeName != 'second' && this.activeName != 'zero' && this.activeName != 'mechanical' && this.activeName != 'mechanical2') this.activeName = 'first'
          }
          this.oldType = nv.departmentType
        }
      }
    },

    created() {
      if (tabIndex.get()) {
        return this.activeName = tabIndex.get()
      } else {
        const params = this.$route.params
        if (params.tabIndex) {
          const tabIndex = params.tabIndex
          if (tabIndex == 1) this.activeName = 'fifth'
          if (tabIndex == 2) this.activeName = 'sixth'
          if (tabIndex == 3) this.activeName = 'eleventh'
        } else {
          this.activeName = 'first'
        }
      }
    },
    beforeDestroy() {
      this.activeName = ''
      tabIndex.set('')
    },

    mounted() {
    },

    methods: {
      // 切换tabs
      handleClick() {
        tabIndex.set(this.activeName)
      }
    }
  }
</script>

<style lang="stylus">
  // @import "../../assets/var"
  .security-check
    // background-color $white
    .none
      display none
    .c-l-l
      color black
    .content
      height 100%
      padding 10px 20px
      .operation
        padding 0 0 10px 0
      .el-table .cell
        text-align center
      .el-table .is-left .cell
        text-align left !important
    .details
      height 600px
      width 100%
      overflow-y auto
      text-align left
      font-size 18px
      .status
        padding 0 20px
        >div
          line-height 40px
      .null
        height 10px
        background #f2f2f2
        border-radius 5px
      .object
        box-sizing border-box
        background-color $white
        border-radius 10px
        padding 0 20px
        .strip
          min-height 40px
          display flex
          align-items center
          border-bottom 1px solid #f2f2f2
          >span
            width 150px
            flex-shrink 0
          >div
            word-break break-word
          .green
            color #32CD32
          .red
            color red
        .bornone
          border-bottom none
          padding-top 10px
          img
            width 150px
            height 160px
            margin 0 20px 10px 0
  .borderTips
    border 1px solid #EBEEF5
    padding 10px
    font-size 14px
    color #606266
</style>
